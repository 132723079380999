$white: var(--white);
$black: var(--black);
$red: var(--red);

$grey-01: var(--grey-01);
$grey-02: var(--grey-02);
$grey-03: var(--grey-03);
$grey-04: var(--grey-04);
$grey-05: var(--grey-05);

$s-green-01: var(--s-green-1);
$s-green-02: var(--s-green-2);
$s-green-03: var(--s-green-3);
$s-green-04: var(--s-green-4);

$green-01: var(--green-1);
$green-01: var(--green-1);
$green-02: var(--green-2);
$green-03: var(--green-3);
$green-04: var(--green-4);

$teal-01: var(--teal-01);
$teal-02: var(--teal-02);
$teal-03: var(--teal-03);

$clay-01: var(--clay-01);
$clay-02: var(--clay-02);
$clay-03: var(--clay-03);

$pink-01: var(--pink-01);
$pink-02: var(--pink-02);
$pink-03: var(--pink-03);

$purple-01: var(--purple-01);
$purple-02: var(--purple-02);
$purple-03: var(--purple-03);

$laurel-01: var(--laurel-01);
$laurel-02: var(--laurel-02);
$laurel-03: var(--laurel-03);

$apricot-01: var(--apricot-01);
$apricot-02: var(--apricot-02);
$apricot-03: var(--apricot-03);

$dark-red: var(--dark-red);
$dark-black: var(--dark-black);
$dark-grey: var(--dark-grey);

$success: var(--success);
$error: var(--error);
$warning: var(--warning);
$information: var(--information);

$btn-primary-bg: var(--btn-primary-bg);
$btn-primary-border: var(--btn-primary-border);
$btn-primary-color: var(--btn-primary-color);
$btn-primary-hover-color: var(--btn-primary-hover-color);
$btn-primary-hover-bg: var(--btn-primary-hover-bg);
$btn-primary-hover-border: var(--btn-primary-hover-border);
$btn-primary-disabled-border: var(--btn-primary-disabled-border);

$btn-secondary-bg: var(--btn-secondary-bg);
$btn-secondary-color: var(--btn-secondary-color);
$btn-secondary-hover-bg: var(--btn-secondary-hover-bg);
$btn-secondary-border: var(--btn-secondary-border);
$btn-secondary-hover-border: var(--btn-secondary-hover-border);
$btn-secondary-hover-color: var(--btn-secondary-hover-color);
$btn-secondary-disabled-color: var(--btn-secondary-disabled-color);

$btn-disabled-bg: var(--btn-disabled-bg);
$btn-disabled-color: var(--btn-disabled-color);

$btn-tertiary-bg: var(--btn-tertiary-bg);
$btn-tertiary-color: var(--btn-tertiary-color);
$btn-tertiary-hover-bg: var(--btn-tertiary-hover-bg);
$btn-tertiary-hover-color: var(--btn-tertiary-hover-color);
$btn-tertiary-disabled-color: var(--btn-tertiary-disabled-color);
$btn-tertiary-border: var(--btn-tertiary-border);
$btn-tertiary-hover-border: var(--btn-tertiary-hover-border);

$btn-transparent-bg: var(--btn-transparent-bg);
$btn-transparent-color: var(--btn-transparent-color);
$btn-transparent-hover-bg: var(--btn-transparent-hover-bg);
$btn-transparent-hover-color: var(--btn-transparent-hover-color);
$btn-transparent-disabled-color: var(--btn-transparent-disabled-color);
$btn-transparent-border: var(--btn-transparent-border);
$btn-transparent-hover-border: var(--btn-transparent-hover-border);

// research

$red: var(--red);
$white: var(--white);

$green-0: var(--green-0);
$green-1: var(--green-1);
$green-2: var(--green-2);
$green-3: var(--green-3);
$green-4: var(--green-4);
$green: var(--green);
$dark-green: var(--dark-green);

$dark-grey: var(--dark-grey);
$mid-grey: var(--mid-grey);
$light-grey: var(--light-grey);

$s-blue-1: var(--s-blue-1);
$s-blue-2: var(--s-blue-2);
$s-blue-3: var(--s-blue-3);
$s-blue-4: var(--s-blue-4);
$s-laurel-3: var(--s-laurel-3);

$s-green-1: var(--s-green-1);
$s-green-2: var(--s-green-2);
$s-green-3: var(--s-green-3);
$s-green-4: var(--s-green-4);

$s-pink-1: var(--s-pink-1);
$s-pink-2: var(--s-pink-2);
$s-pink-3: var(--s-pink-3);
$s-pink-4: var(--s-pink-4);

$s-red-1: var(--s-red-1);
$s-red-2: var(--s-red-2);
$s-orange: var(--s-orange);
$s-yellow: var(--s-yellow);
