.xpack-o-grid-setting {
  position: fixed;
  top: 50%;
  left: 50%;
  width: min(px2rem(500px), 100%);
  height: auto;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);

  &__form {
    display: flex;
    flex-direction: column;
    height: min(px2rem(800px), 100%);
  }

  &__header {
    width: 100%;
    background: #f0f0f0;
    text-align: center;
    font-size: px2rem(35px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 px2rem(20px);
    border-bottom: 1px solid #ccc;
    padding: px2rem(10px) 0;
  }

  &__content {
    flex-grow: 1;
    padding: px2rem(20px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: px2rem(10px);
  }

  &__item {
    display: flex;
    gap: px2rem(15px);
    font-size: px2rem(18px);
    font-weight: bold;
    padding: px2rem(5px);
  }

  &__ta-setting {
    width: 100%;
    height: px2rem(500px);
    font-size: px2rem(16px);
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    justify-content: space-around;
    padding-bottom: px2rem(25px);

    button {
      font-size: px2rem(16px);
      font-weight: bold;
      padding: px2rem(10px) px2rem(20px);
    }
  }
}
