@import '../../src/assets/styles/00-abstracts/abstracts';@import '../../src/assets/styles/01-mixins/mixins';@import './px2rem';
@import './reset';
@import './breakpoint';
@import './theme';
@import './border';
@import './state-toggle';
@import './form';
@import './xpack-root';
@import './top-panel';
@import './grid-setting';

:root {
  --navbar-max-width: px2rem(240px);
}

$-wave-25: #e9eef1;
$-wave-50: #d2dde4;

html {
  font-size: 100% !important;
}

body {
  /*
  The special case for Root page
  */
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: none !important;
  height: 100vh !important;
  max-height: 100vh !important;
  overflow: hidden !important;
  background-color: $body-bg !important;
  font-size: px2rem(16px);
  font-family: Arial, Helvetica, sans-serif;
  color: $body-color;
}

.xpack-t-root {
  height: 100vh;
  height: 100dvh;
  display: grid;
  grid-template-rows: min-content 1fr;

  @include lg {
    grid-template-columns: px2rem(300px) 1fr;
  }

  &.top-panel {
    @include lg {
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr;
    }
  }

  a {
    text-decoration: none;
  }

  &__target-wrapper {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    position: relative;
    justify-self: center;

    &.initialized {
      transition: max-width linear 0.25s;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      width: px2rem(1px);
      height: 100%;
      background-color: $body-color;
    }

    &::before {
      left: -(px2rem(1px));
    }

    &::after {
      right: -(px2rem(1px));
    }
  }

  &__target-resizer {
    width: px2rem(20px);
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(100% + 1px);
    background-color: $-wave-25;
    cursor: ew-resize;

    &:hover {
      background-color: $-wave-50;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: px2rem(5px);
      height: px2rem(50px);
      left: 50%;
      top: 50%;
      border-width: 0 px2rem(1px);
      border-style: solid;
      border-color: $border-color;
      transform: translate(-50%, -50%);
    }
  }

  &__target {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: none;
  }

  &.top-panel .xpack-t-root__target-wrapper {
    height: 100%;
  }
}
