.xpack-o-root {
  @include border-right;
  height: min-content;
  display: flex;
  flex-direction: row;
  gap: px2rem(1px);
  overflow: hidden auto;
  background-color: $body-bg;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  line-height: 1.5;
  padding: px2rem(10px);
  font-size: px2rem(14px);
  align-items: center;

  @include lg {
    width: auto;
    flex-direction: column;
    height: 100vh;
    --navbar-max-width: 100%;
    position: relative;
    width: 100%;
    box-shadow: unset;
    padding: 0;
    align-items: stretch;
  }

  &__title {
    display: flex;
    align-items: center;

    @include lg {
      display: none;
    }
  }

  &__button-close,
  &__button-setting {
    width: px2rem(35px);
    height: px2rem(35px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: $body-color;

    svg.xpack-o-root__control-svg {
      height: px2rem(35px);
      width: px2rem(35px);
    }

    &:hover {
      color: $link-hover-color;
    }

    @include lg {
      display: none;
    }
  }

  &__button-setting {
    padding: px2rem(2px);
  }

  &__nav-items {
    flex-grow: 1;
    position: fixed;
    top: px2rem(50px);
    left: 0;
    height: calc(100vh - px2rem(275px));
    max-width: var(--navbar-max-width);
    transform: translateX(-100%);
    background-color: $body-bg;
    border-bottom: px2rem(1px) solid $border-color;
    cursor: pointer;

    &.show {
      display: block;
      box-shadow: 0 px2rem(2px) px2rem(4px) #a6a6a6;
      transform: translateX(0);
    }

    @include lg {
      position: relative;
      top: 0;
      display: block;
      padding-bottom: px2rem(8px);
      transform: translateX(0);
    }
  }

  &__items {
    padding: px2rem(8px) 0 px2rem(32px);
    height: calc(100% - px2rem(42px));
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: px2rem(5px);
      height: px2rem(5px);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover,
    &:focus-visible {
      scrollbar-width: thin;
      scrollbar-color: $scrollbar-thumb-hover-bg $body-bg;

      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-bg;
      }
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $scrollbar-thumb-hover-bg;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  &__search {
    padding: px2rem(8px) px2rem(16px);
    border-bottom: px2rem(1px) solid $border-color;

    input {
      @include input;
      width: 100%;
      padding: px2rem(8px);
      font-size: px2rem(16px);
    }
  }

  &__nav-message {
    padding: px2rem(8px) px2rem(14px);
    font-weight: 700;
  }

  &__search-matches {
    border-bottom: px2rem(1px) solid $border-color;

    > * {
      font-weight: 700;
    }
  }

  &__search-not-matches {
    &.blur {
      opacity: 0.5;
    }
  }

  &__nav-item {
    padding: px2rem(4px) px2rem(8px) px2rem(4px) px2rem(16px);
    display: block;
    color: $body-color;
    font-size: px2rem(14px);
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;

    &.grid-setting {
      display: flex;
      justify-content: space-between;

      a {
        color: $body-color;

        &:hover {
          color: $link-hover-color;
        }
      }

      button {
        color: $body-color;
        border: none;
        background: transparent;
        &:hover {
          color: $link-hover-color;
          cursor: pointer;
        }
      }
    }

    &--active {
      color: $link-hover-color;
      background-color: rgba($body-color-rgb, 0.2);
    }

    &:hover {
      color: $link-hover-color;
    }

    &.panel-position {
      display: none;

      @include lg {
        display: flex;
      }
    }

    &--last {
      margin-bottom: px2rem(20px);
    }
  }

  &__nav-item-collection {
    padding: px2rem(4px) px2rem(8px) px2rem(4px) px2rem(16px);
    & > summary {
      color: #9453d5;
      cursor: pointer;
      font-weight: bold;
      display: flex;
      align-items: center;
      user-select: none;

      @include dark {
        color: #40e0d0;
      }

      &::marker {
        content: none;
      }

      &::after {
        text-shadow: 0 0 #9453d5;
        content: '\2795';
        margin-left: auto;
        flex-shrink: 0;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($color: #000000, $alpha: 0);
        font-size: px2rem(10px);

        @include dark {
          text-shadow: 0 0 #40e0d0;
        }
      }
    }

    &[open] {
      & > summary {
        &::after {
          content: '\2796';
        }
      }
    }
  }

  &__nav-item-seperator {
    @include border-top;
    margin: px2rem(16px) 0 px2rem(8px);
  }

  &__frame-control {
    display: block;
    max-width: var(--navbar-max-width);
    margin-left: auto;

    @include lg {
      margin-left: unset;
    }
  }

  &__active-item-options {
    display: block;
    max-width: var(--navbar-max-width);
    background-color: $body-bg;
    position: fixed;
    top: px2rem(50px);
    right: 0;
    padding: px2rem(20px) 0 px2rem(8px);
    transform: translateX(100%);

    &.show {
      display: block;
      box-shadow: 0 px2rem(2px) px2rem(4px) #a6a6a6;
      transform: translateX(0);
    }

    @include lg {
      display: block;
      position: relative;
      transform: translateX(0);
      top: 0;
    }
  }

  &__actual-width {
    margin-top: px2rem(8px);
    margin-right: px2rem(10px);
    text-align: center;
    font-size: px2rem(14px);
  }

  &__controls {
    margin-top: px2rem(10px);
    text-align: center;
    display: none;

    @include lg {
      display: block;
    }
  }

  &__control-button {
    border: none;
    background-color: transparent;
    color: $body-color;
    cursor: pointer;

    &.active {
      color: $link-hover-color;
    }

    &:hover {
      color: $link-hover-color;
    }
    &:has(.icon-xl) {
      position: relative;
      &::before {
        content: 'xl';
        font-size: px2rem(10px);
        font-weight: 700;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__control-svg {
    width: px2rem(18px);
    height: px2rem(18px);

    &.icon-xl {
      width: px2rem(24px);
      height: px2rem(24px);
    }
  }
}
